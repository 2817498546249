.background {
  background-color: #222222;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 10px;
  padding: 10px;
  justify-items: center;
  align-items: center;
}

.grid-item-title {
  /* background-color: blue; */
  grid-column: span 2;
  margin-top: 50px;
}

.grid-item-item-Maxsun {
  background-color: #44af35;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.grid-item-item-Sades {
  background-color: #00afef;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
.grid-item-item-TitanArmy {
  background-color: #009542;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.announcement {
  background-color: #222222;
  color: #ffffff;
  font-size: 40px;
  padding: 0px;
  text-align: center;
  width: auto;
}

.grid-container-FBLIKE {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
  padding: 10px;
  justify-items: center;
  align-items: center;
}

.grid-item-item-FBLIKE {
  /* background-color: #44a; */
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
