.chat-window .chat-body {
  height: calc(500px - (45px + 70px));
  border: 1px solid #263238;
  background: #fff;

  /* position: relative; */
}

.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 1px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 120px;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-end;
}

#you .message-content {
  justify-content: flex-end;
}

#you .message-meta {
  justify-content: flex-end;
  margin-left: 5px;
  margin-top: -20px;
}

#other {
  justify-content: flex-start;
}

#other .message-content {
  justify-content: flex-start;
  background-color: cornflowerblue;
}

#other .message-meta {
  justify-content: flex-start;
  margin-right: 5px;
  margin-top: -20px;
}

.message-meta #author {
  /* margin-left: 10px; */
  /* font-weight: bold; */
}
